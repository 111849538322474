<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-text v-if="o_Question">
          <v-card outlined class="d-flex justify-space-between">
            <div class="ma-2">
              <template v-if="o_Question.i_Status == 3">
                <v-chip
                  color="success"
                  label
                  small
                  text-color="white"
                  class="mt-2"
                >
                  <v-icon small left> mdi-check </v-icon>
                  {{ $t("general.a_Status") }}{{ $t("general.definitely") }}
                </v-chip>
              </template>
              <template v-else-if="o_Question.i_Status == 0">
                <v-chip
                  color="warning"
                  label
                  small
                  text-color="white"
                  class="mt-2"
                >
                  {{ $t("general.draft") }}
                </v-chip>
              </template>
              <template v-else-if="o_Question.i_Status == 1">
                <v-chip
                  color="warning"
                  label
                  small
                  text-color="white"
                  class="mt-2"
                >
                  QSK
                </v-chip>
              </template>
              <template v-else-if="o_Question.i_Status == 2">
                <v-chip
                  color="warning"
                  label
                  small
                  text-color="white"
                  class="mt-2"
                >
                  {{ $t("general.in_translation") }}
                </v-chip>
              </template>
            </div>
            <base-hover-button
              class="ma-2"
              color="primary lighten-5"
              text-color="primary--text"
              :text="$t('general.back')"
              @click.native="$router.back()"
              iconName=" mdi-arrow-left-circle"
            />
          </v-card>
          <!--Number-->
          <v-card outlined class="d-flex justify-space-between pa-1 my-1">
            <div>
              <v-text-field
                readonly
                v-model="o_Question.s_Number"
                :label="$t('general.number')"
                required
                type="number"
              />
            </div>
            <div />
          </v-card>
          <!--End--Number-->
          <!--Vertiefungsmodul-->
          <v-card v-if="o_Question.i_TestTypeID !==3" outlined class="d-flex justify-space-between pa-1 my-1">
            <div>
              <v-select
                readonly
                class="pt-3"
                dense
                :items="$t('general.a_AdvancedModule')"
                item-text="s_Name"
                item-value="s_Shortname"
                v-model="o_Question.s_AdvancedModule"
                :label="$t('general.advanced_module')"
              ></v-select>
            </div>
            <div />
          </v-card>
          <!--End--Vertiefungsmodul-->
          <!---Wahlmodul-->
          <v-card v-if="o_Question.i_TestTypeID !==3" outlined class="d-flex justify-space-between pa-1 my-1">
            <div>
              <v-select
                readonly
                class="pt-3"
                dense
                :items="$t('general.a_ElectiveModule')"
                item-text="s_Name"
                item-value="s_Shortname"
                v-model="o_Question.s_ElectiveModule"
                :label="$t('general.elective_module')"
              ></v-select>
            </div>
            <div />
          </v-card>
          <!--End-Wahlmodul-->
          <!---Stichwort-->
          <v-card
            v-if="o_Question.i_TestTypeID !==3"
            outlined
            class="d-flex justify-space-between pa-1 my-1"
          >
            <div>
              <v-select
                readonly
                class="pt-3"
                dense
                item-value="s_Id"
                item-text="s_Text"
                :items="$t('a_Tags')"
                v-model="o_Question.s_Tag"
                :label="$t('general.tag')"
              ></v-select>
            </div>
            <div />
          </v-card>
          <!--End-Stichwort-->
          <!--Designation-->
          <v-card outlined class="d-flex justify-start pa-1 my-1">
            <v-row >
          <v-col cols="12" xs="4" sm="7" md="8" lg="6" xl="4">
              <v-text-field
                readonly
                v-model="o_Question.s_Designation"
                :label="$t('general.short_description')"
                required
              />
          </v-col>
            </v-row>
            <div class=""></div>
          </v-card>
          <!--End--Designation-->
          <!--Beschreibung-->
          <v-card outlined class="pa-1 my-1">
            <div class="d-flex justify-space-between">
              <h6 class="font-weight-bold">{{ $t("general.description") }}:</h6>
            </div>
            <div v-html="o_Question.s_Description" />
          </v-card>
          <!--End--Beschreibung-->
          <!--Rolle der Kandidatin-->
          <v-card
            v-if="o_Question.i_TestTypeID == 1"
            outlined
            class="pa-1 my-1"
          >
            <div class="d-flex justify-space-between">
              <h6 class="font-weight-bold">
                {{ $t("general.role_of_the_candidate") }}:
              </h6>
            </div>
            <div v-html="o_Question.s_Introduction" />
          </v-card>
          <!--End--Rolle der Kandidatin-->
          <!--Ausgangssituation-->
          <v-card outlined class="pa-1 my-1">
            <div class="d-flex justify-space-between">
              <h6 class="font-weight-bold">
                {{ $t("general.initial_situation") }}:
              </h6>
            </div>
            <div v-html="o_Question.s_Situation" />
          </v-card>
          <!--End--Ausgangssituation-->
          <!--Test Presentation-->
          <v-card
            v-if="o_Question.i_TestTypeID == 2"
            outlined
            class="pa-1 my-1"
          >
            <div class="d-flex justify-space-between">
              <h6 class="font-weight-bold">
                {{ $t("general.task_definition") }}:
              </h6>
            </div>
            <div v-html="o_Question.s_TestPresentation" />
          </v-card>
          <!--End--Test Presentation-->
          <!--SolutionPresentation-->
          <v-card
            v-if="o_Question.i_TestTypeID == 2 || o_Question.i_TestTypeID == 3"
            outlined
            class="pa-1 my-1"
          >
            <div class="d-flex justify-space-between">
              <h6 class="font-weight-bold">
                {{ $t("general.solution_presentation") }}:
              </h6>
            </div>
            <div v-html="o_Question.s_SolutionPresentation" />
          </v-card>
          <!--End--SolutionPresentation-->
          <v-divider class="my-4" />
          <div class="my-4" />
          <!--Frage an den Kandidaten/an die Kandidatin:-->
          <div v-if="o_Question.i_TestTypeID !==3">
            <h5 class="font-weight-bold">
              {{ $t("general.questions_for_the_candidate") }}:
            </h5>
            <v-card
              outlined
              class="pa-1 my-1"
              v-for="(question, index) in o_Question.a_Questions"
              :key="index"
            >
              <div class="d-flex justify-space-between">
                <h6>{{ $t("general.question") }}: {{ index + 1 }}</h6>
              </div>
              <div v-html="question.s_Question" />
              <v-divider class="mb-2"></v-divider>
              <h6>{{ $t("general.answer") }} {{ index + 1 }} :</h6>
              <div v-html="question.s_ExpectedAnswer" />
            </v-card>
          </div>
          <!--End--Frage an den Kandidaten/an die Kandidatin:-->
          <div class="d-flex justify-end">
            <base-hover-button
              class="mt-1"
              color="primary lighten-5"
              text-color="primary--text"
              :text="$t('general.back')"
              @click.native="$router.back()"
              iconName=" mdi-arrow-left-circle"
            />
          </div>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "viewQuestion",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.$t("general.question_view"),
    };
  },
  props: {
    o_Question: {
      type: Object,
    },
  },
  created() {
    if (this.o_Question == undefined) {
      //go to the last page
      this.$router.back();
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-label {
  color: rgba(0, 0, 0, 0.87);
}
::v-deep .theme--light.v-label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 22px;
  font-weight: bold;
}
</style>
